import { Alert, Button, Input, Row, Space } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Password from "antd/lib/input/Password";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link, Redirect } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { AuthLayout, AuthHeader } from "./AuthLayout";
import './Login.scss';

interface FormData {
    email: string;
    password: string;
}

export function LoginForm(props: {}) {
    const { login, user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [form] = useForm();

    const isLoggedIn = user !== undefined;

    const handleSubmit = async (values: FormData) => {
        setError('');
        setIsLoading(true);
        try {
            await login(values.email, values.password)
        } catch(e) {
            if(e.code === 'auth/user-not-found' || e.code === 'wrong-password') {
                setError('Zadaný email nebo heslo není správné.');
            } else if (e.code === "auth/too-many-requests") {
                setError('Bylo dosaženo limitu na počet chybných přihlášení. Za moment to můžete zkusit znovu.');
            } else {
                setError('Uživatele nebylo možné přihlásit, zkontrolujte přihlašovací údaje a zkuste to znovu.');
            }
        }
        setIsLoading(false);
    }

    const LoginLayout = () => (
        <AuthLayout className="login-form-container" isLoading={isLoading}>                
            <Space direction="vertical" size={20}>

                <AuthHeader />
                
                {error && <Alert type="error" message={error} />}

                <Form
                    onFinish={handleSubmit}
                    form={form}
                >
                    <FormItem noStyle>
                        <Title level={3}>Přihlášení</Title>
                    </FormItem>

                    <FormItem
                        name="email"
                        rules={[{required: true, type: "email"}]}
                    >
                        <Input placeholder="Email" />
                    </FormItem>
                
                    <FormItem
                        name="password"
                        rules={[{required: true}]}
                    >
                        <Password placeholder="Heslo" />
                    </FormItem>

                    <FormItem>
                        <Button htmlType="submit" type="primary" block>Přihlásit</Button>
                        
                        <Row justify="end">
                            <Button type="link"><Link to="/reset-password">Zapomenuté heslo</Link></Button>
                        </Row>
                    </FormItem>

                </Form>
            </Space>

            <Row justify="end">
                
            </Row>
        </AuthLayout>
    );

    return isLoggedIn ? <Redirect to="/" /> : <LoginLayout />;
}
