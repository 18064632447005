import "firebase/firestore";
import { JsonDecoder } from "ts.data.json";

export function decodeDocuments<
  T,
  DocumentDecoder extends JsonDecoder.Decoder<T>
>(
  docs: Array<firebase.default.firestore.QueryDocumentSnapshot>,
  documentDecoder: DocumentDecoder
): Promise<T[]> {
  const identifiedDocs = docs.map((snapshot) => ({
    id: snapshot.id,
    ...snapshot.data(),
  }));

  return JsonDecoder.array(
    documentDecoder,
    "Array of documents"
  ).decodeToPromise(identifiedDocs);
}

export function decodeDocument<
  T,
  DocumentDecoder extends JsonDecoder.Decoder<T>
>(
  snapshot: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>,
  documentDecoder: DocumentDecoder
): Promise<T> {
  return documentDecoder.decodeToPromise({
    id: snapshot.id,
    ...snapshot.data(),
  });
}
