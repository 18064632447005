import { Card, PageHeader, Table } from "antd";
import { useEffect, useState } from "react";
import { getTopics } from "../../api/Topic";
import { Topic  } from '../../model/Topic';

const columns = [
    {
        title: 'Název',
        dataIndex: 'title',
        key: 'title'
    }
]

export function Topics() {
    return (
        <Card>
            <PageHeader title="Okruhy" onBack={undefined}/>
            <TopicsList />
        </Card>
    );
}

function TopicsList() {
    const [topicsList, setTopicsList] = useState<Topic[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const loadTopics = async () => {
            setIsLoading(true);
            const topics = await getTopics();
            setIsLoading(false);
            setTopicsList(topics);
        };
        loadTopics();
    }, []);

    return <Table
        loading={isLoading}
        dataSource={topicsList}
        rowKey="id"
        columns={columns}
        pagination={false}
    />;
}
