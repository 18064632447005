import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "./App.css";
import { Button, Layout, Menu, Row } from "antd";
import {
  ProjectOutlined,
  AppstoreAddOutlined,
  CalculatorOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Topics } from "./components/topicsList/TopicsList";
import { Home } from "./components/home/Home";
import { Questions } from "./components/questions/Questions";
import { Content, Header } from "antd/lib/layout/layout";
import { LoginForm } from "./components/auth/Login";
import { ResetPasswordForm } from "./components/auth/ResetPassword";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import Logo from "./assets/logo.svg";
import { Logout } from "./components/auth/Logout";

const protectedRoutes = [
  { path: "/", content: Home, exact: true },
  { path: "/topics", content: Topics },
  { path: "/questions", content: Questions },
];

const authRoutes = [
  { path: "/login", component: LoginForm },
  { path: "/logout", component: Logout },
  { path: "/reset-password", component: ResetPasswordForm },
];

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            {/* Protected Routes */}

            {protectedRoutes.map((route) => {
              return (
                <ProtectedRoute
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                >
                  <Page>
                    <route.content />
                  </Page>
                </ProtectedRoute>
              );
            })}

            {/* Auth Routes */}

            {authRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

const Page: React.FC = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ background: "#fff" }}>
        <Row align="middle" justify="space-between">
          <Link to="/" className="logo" />

          <Menu mode="horizontal">
            <Menu.Item icon={<LogoutOutlined />}>
              <Link to="/logout">Logout</Link>
            </Menu.Item>
          </Menu>
        </Row>
      </Header>

      <Layout>
        <Sidebar />

        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

const menuItems = [
  { name: "Přehled", route: "/", exact: true, icon: <ProjectOutlined /> },
  { name: "Okruhy", route: "/topics", icon: <AppstoreAddOutlined /> },
  { name: "Otázky", route: "/questions", icon: <CalculatorOutlined /> },
];

function Sidebar() {
  return (
    <Layout.Sider theme="light">
      <Menu mode="inline">
        {menuItems.map((item) => {
          return (
            <Menu.Item key={item.route}>
              <Link to={item.route}>{item.name}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Layout.Sider>
  );
}

export default App;
