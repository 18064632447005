import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FirestorePage,
  getQuestions,
  PagingDirection,
  QueryCursor,
} from "../../api/Question";
import { useQuery } from "../../hooks/SearchQuery";
import { Question } from "../../model/Question";

const columns = [
  {
    title: "Otázka",
    dataIndex: "summary",
    render: (title: string, question: Question) => {
      return <Link to={`/questions/${question.id}`}>{title}</Link>;
    },
  },
  {
    title: "Tags",
    dataIndex: "tags",
    render: (tags: string[]) => {
      return (
        <>
          {tags.map((tag) => (
            <Tag>{tag}</Tag>
          ))}
        </>
      );
    },
  },
];

export function QuestionsList() {
  const [isLoading, setIsLoading] = useState(false);
  const [questionsPage, setQuestionsPage] = useState<
    FirestorePage<Question> | undefined
  >();
  const [error, setError] = useState<string | undefined>();
  const pageSize = 10;
  const [documentQueryPaging, setDocumentQueryPaging] =
    useDocumentQueryPaging();

  const loadQuestions = async (
    direction: PagingDirection,
    cursor?: QueryCursor
  ) => {
    setIsLoading(true);
    setError(undefined);
    try {
      const questions = await getQuestions(direction, cursor, pageSize);
      setQuestionsPage(questions);
      setDocumentQueryPaging(questions.start);
    } catch (error: any) {
      setError(error.toString());
    }
    setIsLoading(false);
  };

  const CustomPaging = () => {
    return (
      <CursorPaging
        canGoBack={questionsPage?.start !== undefined}
        canGoForward={
          questionsPage?.end !== undefined &&
          questionsPage.content.length === pageSize
        }
        onPrev={() => {
          loadQuestions("before", questionsPage?.start);
        }}
        onNext={() => {
          loadQuestions("after", questionsPage?.end);
        }}
      />
    );
  };

  useEffect(() => {
    loadQuestions("start", documentQueryPaging);
  }, []);

  return (
    <Col>
      {error && <Alert message={error} type="error" />}

      <Table
        loading={isLoading}
        dataSource={questionsPage?.content ?? []}
        pagination={false}
        columns={columns}
        footer={() => <CustomPaging />}
        rowKey="id"
      />
    </Col>
  );
}

function CursorPaging(props: {
  onPrev: () => void;
  onNext: () => void;
  canGoBack: boolean;
  canGoForward: boolean;
}) {
  return (
    <Space>
      <Button
        icon={<LeftOutlined />}
        disabled={!props.canGoBack}
        onClick={() => props.onPrev?.()}
      />
      <Button
        icon={<RightOutlined />}
        disabled={!props.canGoForward}
        onClick={() => props.onNext?.()}
      />
    </Space>
  );
}

const useDocumentQueryPaging = (): [
  QueryCursor | undefined,
  (cursor?: QueryCursor) => void
] => {
  const [getQuery, setQuery] = useQuery();
  const [cursor, setCursor] = useState<QueryCursor | undefined>(
    getQuery("start")
  );

  return [
    cursor,
    (cursor) => {
      setCursor(cursor);
      setQuery({ start: getCursorId(cursor) });
    },
  ];
};

function getCursorId(cursor?: QueryCursor): string | undefined {
  if (cursor === undefined) {
    return undefined;
  } else if (typeof cursor === "string") {
    return cursor;
  } else {
    return cursor.id;
  }
}
