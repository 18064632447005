import { Col, Row } from "antd"
import './DevicePreview.scss';

export const DevicePreview: React.FC = ({children}) => {
    return (
        <Col span={24} className="preview-wrapper">
            <div className="preview-container">
                <Col span={24} className="preview-content">
                    {children}
                </Col>
            </div>
        </Col>
    );
};