import { Result } from "antd";
import firebase from "firebase";
import { err, JsonDecoder, ok } from "ts.data.json";

export interface Question {
  id: String;
  summary: String;
  topicId: String;
  complexity: Complexity;
  timeComplexity: number;
  type: QuestionType;
  question: ContentBlock[];
  answers: Answer[];
  tags: string[];
  dateCreated: Date;
  unformattedSolution?: string;
}

export interface CreateQuestionDto {
  summary: String;
  topicId: String;
  complexity: Complexity;
  timeComplexity: number;
  type: QuestionType;
  question: ContentBlock[];
  answers: {
    isCorrect: Boolean;
    answer: ContentBlock;
  }[];
  unformattedSolution?: String;
  tags: string[];
}

export interface Answer {
  id: String;
  isCorrect: Boolean;
  answer: ContentBlock;
}

export type Complexity = "trivial" | "easy" | "medium" | "hard";

export type QuestionType = "single-choice" | "multi-choice";

export type ContentType = "math";

export interface ContentBlock {
  type: ContentType;
  content: String;
}

/**
 * Decoders
 */

const timestampDecoder = new JsonDecoder.Decoder<firebase.firestore.Timestamp>(
  (json) => {
    if (json instanceof firebase.firestore.Timestamp) {
      return ok(json);
    } else {
      return err(
        `Unexpected type '${typeof json}', expected 'firebase.firestore.Timestamp' instead.`
      );
    }
  }
);

export const complexityDecoder = JsonDecoder.oneOf<Complexity>(
  [
    JsonDecoder.isExactly("trivial"),
    JsonDecoder.isExactly("easy"),
    JsonDecoder.isExactly("medium"),
    JsonDecoder.isExactly("hard"),
  ],
  "Complexity"
);

export const questionTypeDecoder = JsonDecoder.oneOf<QuestionType>(
  [
    JsonDecoder.isExactly("single-choice"),
    JsonDecoder.isExactly("multi-choice"),
  ],
  "QuestionType"
);

export const contentTypeDecoder = JsonDecoder.oneOf<ContentType>(
  [JsonDecoder.isExactly("math")],
  "ContentType"
);

export const contentBlockDecoder = JsonDecoder.object<ContentBlock>(
  {
    type: contentTypeDecoder,
    content: JsonDecoder.string,
  },
  "ContentBlock"
);

export const answerDecoder = JsonDecoder.object<Answer>(
  {
    id: JsonDecoder.string,
    isCorrect: JsonDecoder.optional(JsonDecoder.boolean).map(
      (isCorrect) => isCorrect ?? false
    ),
    answer: contentBlockDecoder,
  },
  "Answer"
);

export const questionDecoder = JsonDecoder.object<Question>(
  {
    id: JsonDecoder.string,
    summary: JsonDecoder.string,
    topicId: JsonDecoder.string,
    complexity: complexityDecoder,
    timeComplexity: JsonDecoder.number,
    type: questionTypeDecoder,
    tags: JsonDecoder.optional(
      JsonDecoder.array(JsonDecoder.string, "Tags")
    ).map((tags) => tags ?? []),
    question: JsonDecoder.array<ContentBlock>(
      contentBlockDecoder,
      "Question Blocks"
    ),
    answers: JsonDecoder.array<Answer>(answerDecoder, "Answers"),
    dateCreated: timestampDecoder.map((timestamp) => timestamp.toDate()),
    unformattedSolution: JsonDecoder.optional(JsonDecoder.string),
  },
  "Question"
);
