import { Button, Card, PageHeader } from "antd";
import { Route, Switch, useRouteMatch } from "react-router";
import { Link, useParams } from "react-router-dom";
import { QuestionForm } from "../questionForm/QuestionForm";
import { QuestionsList } from "../questionsList/QuestionsList";

function AddQuestionButton() {
  const { url } = useRouteMatch();

  return (
    <Button type="primary">
      <Link to={`${url}/new`}>Přidat otázku</Link>
    </Button>
  );
}

export function Questions() {
  const { path } = useRouteMatch();

  return (
    <Card>
      <Switch>
        <Route path={path} exact>
          <PageHeader
            title="Otázky"
            onBack={undefined}
            extra={AddQuestionButton()}
          >
            <QuestionsList />
          </PageHeader>
        </Route>

        <Route path={`${path}/new`} component={QuestionForm} />

        <Route path={`${path}/:questionId`} component={QuestionForm} />
      </Switch>
    </Card>
  );
}
