import React from "react";
import { Redirect, Route } from "react-router";
import { RouteProps } from "react-router-dom";
import { useAuth } from "../../context/AuthContext"

export const ProtectedRoute: React.FC<RouteProps> = ({...props}) => {
    const { user } = useAuth();
    const isLoggedIn = user !== undefined;

    if (isLoggedIn) {
        return <Route {...props} />;
    } else {
        return <Redirect to="/login" />;
    }
};
