import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  PageHeader,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import FormList from "antd/lib/form/FormList";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";
import { Rule } from "rc-field-form/lib/interface";
import { ReactNode, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { createQuestion, getQuestion } from "../../api/Question";
import { getTopics } from "../../api/Topic";
import { Complexity, QuestionType } from "../../model/Question";
import { Topic } from "../../model/Topic";
import { DevicePreview } from "../devicePreview/DevicePreview";
import TeX from "@matejmazur/react-katex";
import Text from "antd/lib/typography/Text";
import PreviewCss from "./QuestionPreview.module.scss";
import ErrorList from "antd/lib/form/ErrorList";
import { extractLaTeXBlocks, LaTeXBlock } from "../QuestionPreview/Math";
import { renderToString } from "katex";

const { Option } = Select;

interface FormData {
  summary: String;
  topic: String;
  complexity: Complexity;
  timeComplexity: number;
  question: String;
  type: QuestionType;
  answers: FormAnswer[];
  unformattedSolution?: String;
  tags?: string[];
}

type RawForm = Partial<FormData>;

interface FormAnswer {
  text: string;
  isCorrect?: boolean;
}

interface FormValue {
  name: string[];
  value?: any;
}

interface PreviewData {
  questionType?: QuestionType;
  question?: string;
  answers: PreviewAnswer[];
}

interface PreviewAnswer {
  text: string;
  isCorrect?: boolean;
}

export function QuestionForm() {
  const { questionId } = useParams<{ questionId?: string }>();
  const [form] = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [previewData, setPreviewData] = useState<PreviewData | undefined>(
    undefined
  );
  const [submissionError, setSubmissionError] = useState<string | undefined>(
    undefined
  );
  const [existingDataError, setExistingDataError] = useState<
    string | undefined
  >();
  const defaultRules: Rule[] = [{ required: true }];
  const history = useHistory();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setTopics(await getTopics());

      if (questionId !== undefined) {
        try {
          const question = await getQuestion(questionId);
          setPreviewData({
            questionType: question.type,
            question: question.question.find(() => true)?.content.toString(),
            answers: question.answers.map((a) => ({
              text: a.answer.content.toString(),
              isCorrect: a.isCorrect === true,
            })),
          });
          form.setFieldsValue({
            summary: question.summary,
            topic: question.topicId,
            complexity: question.complexity,
            timeComplexity: question.timeComplexity / 60,
            question: question.question.find(() => true)?.content,
            type: question.type,
            answers: question.answers.map((a) => {
              console.log("sex:", a);
              return {
                text: a.answer.content.toString(),
                isCorrect: a.isCorrect === true,
              };
            }),
            unformattedSolution: question.unformattedSolution,
            tags: question.tags,
          });
        } catch (error: any) {
          setExistingDataError(error);
        }
      }

      form.getFieldsValue();

      setIsLoading(false);
    };
    loadData();
  }, []);

  const validateAnswers = async (values?: FormAnswer[]) => {
    const answers: FormAnswer[] = (values ?? []).filter((answer) => answer);
    const correctAnswersCount = answers.filter(
      (answer) => answer.isCorrect === true
    ).length;

    if (answers.length < 2) {
      throw new Error("Vyplňte alespoň 2 možnosti odpovědi.");
    } else if (
      previewData?.questionType === "single-choice" &&
      correctAnswersCount !== 1
    ) {
      throw new Error(
        "Zvolený typ otázky musí mít právě jednu správnou odpověď."
      );
    } else if (
      previewData?.questionType === "multi-choice" &&
      correctAnswersCount < 1
    ) {
      throw new Error(
        "Zvolený typ otázky musí mít alespoň jednu správnou odpověď."
      );
    }
  };

  const validateLaTeXText = async (value?: string) => {
    let blocks: LaTeXBlock[];

    // Validate that math blocks are correctly enclosed by delimiters
    try {
      blocks = extractLaTeXBlocks(value ?? "");
    } catch (e: any) {
      throw new Error(
        `Blok neobsahuje vyžadovaný uzavírací oddělovač '${e.expected}'.`
      );
    }

    // Validate that each block is a valid KaTeX
    try {
      blocks.forEach((block) => {
        if (block.delimiter !== undefined) {
          renderToString(block.text, { throwOnError: true });
        }
      });
    } catch {
      throw new Error(
        "Jeden nebo více matematických bloků nemá správný formát."
      );
    }
  };

  const validateTimeComplexity = async (value?: number) => {
    const complexity = value ?? 0;

    if (complexity < 1) {
      throw new Error("Číslo musí být větší nebo rovno 1.");
    }
  };

  const onFormChange = (values: FormValue[]) => {
    const question: string | undefined = values.find(
      (value) => value.name.includes("question") && value.name.length == 1
    )?.value;
    const questionType: QuestionType | undefined = values.find(
      (value) => value.name.includes("type") && value.name.length == 1
    )?.value;
    const answers: FormAnswer[] | undefined = values.find(
      (value) => value.name.includes("answers") && value.name.length == 1
    )?.value;
    const previewData = {
      question: question,
      questionType: questionType,
      answers: answers?.filter((value) => value) ?? [],
    };

    setPreviewData(previewData);
  };

  const submit = (values: FormData) => {
    // Prevent updating existing question
    if (questionId !== undefined) {
      return;
    }

    const submitForm = async () => {
      setIsLoading(true);
      setSubmissionError(undefined);
      try {
        await createQuestion({
          summary: values.summary,
          topicId: values.topic,
          complexity: values.complexity,
          timeComplexity: values.timeComplexity * 60,
          type: values.type,
          question: [{ type: "math", content: values.question }],
          answers: values.answers.map((value) => ({
            isCorrect: value.isCorrect === true,
            answer: { type: "math", content: value.text },
          })),
          unformattedSolution: values.unformattedSolution,
          tags: values.tags ?? [],
        });
        history.push("/questions");
      } catch (e) {
        setSubmissionError(`${e}`);
      }
      setIsLoading(false);
    };
    submitForm();
  };

  return (
    <>
      <PageHeader title={questionId ? "Upravit otázku" : "Vytvořit otázku"} />

      {!existingDataError && (
        <Spin spinning={isLoading} size="large">
          <Row gutter={20}>
            <Col span={12}>
              {submissionError && (
                <Alert
                  type="error"
                  message="Při ukládání otázky se vyskytla chyba."
                  description={submissionError}
                />
              )}

              <Form
                form={form}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                onFinish={submit}
                onFieldsChange={(_, values) =>
                  onFormChange(values as FormValue[])
                }
                hideRequiredMark
              >
                <Divider orientation="left">Vlastnosti otázky</Divider>

                <FormItem label="Popis" name="summary" rules={defaultRules}>
                  <Input placeholder="Krátký popis otázky" />
                </FormItem>

                <FormItem label="Okruh" name="topic" rules={defaultRules}>
                  <Select placeholder="Vyberte okruh">
                    {topics.map((topic) => {
                      return (
                        <Option
                          key={topic.id.toString()}
                          value={topic.id as string}
                        >
                          {topic.title}
                        </Option>
                      );
                    })}
                    ;
                  </Select>
                </FormItem>

                <FormItem
                  label="Náročnost"
                  name="complexity"
                  rules={defaultRules}
                >
                  <Radio.Group>
                    <Radio.Button value="trivial">Triviální</Radio.Button>
                    <Radio.Button value="easy">Lehká</Radio.Button>
                    <Radio.Button value="medium">Střední</Radio.Button>
                    <Radio.Button value="hard">Těžká</Radio.Button>
                  </Radio.Group>
                </FormItem>

                <FormItem label="Typ otázky" name="type" rules={defaultRules}>
                  <Radio.Group>
                    <Radio.Button value="single-choice">
                      Jedna správná odpověď
                    </Radio.Button>
                    <Radio.Button value="multi-choice">
                      Více správných odpovědí
                    </Radio.Button>
                  </Radio.Group>
                </FormItem>

                <FormItem label="Časová náročnost">
                  <FormItem
                    name="timeComplexity"
                    noStyle
                    rules={[
                      { required: true },
                      {
                        validator: async (_, value) =>
                          validateTimeComplexity(value),
                      },
                    ]}
                  >
                    <InputNumber placeholder="5" min={0} max={90} />
                  </FormItem>

                  <span className="ant-form-text"> minut</span>
                </FormItem>

                <Divider orientation="left">Zadání</Divider>

                <FormItem
                  label="Text otázky"
                  name="question"
                  rules={[
                    { required: true },
                    { validator: (_, value) => validateLaTeXText(value) },
                  ]}
                >
                  <TextArea
                    placeholder="Pro která $x$ platí následující rovnice:"
                    rows={6}
                  />
                </FormItem>

                <Divider orientation="left">Odpovědi</Divider>

                <FormList
                  name="answers"
                  rules={[
                    {
                      validator: async (_, values: FormAnswer[]) =>
                        validateAnswers(values),
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <FormItem
                          {...field}
                          name={undefined}
                          label={`Možnost ${index + 1}`}
                          dependencies={["type"]}
                        >
                          {() => (
                            <>
                              <FormItem
                                name={[field.name, "text"]}
                                rules={[
                                  { required: true },
                                  {
                                    validator: (_, value) =>
                                      validateLaTeXText(value),
                                  },
                                ]}
                              >
                                <Input placeholder="$2x + 1$" />
                              </FormItem>

                              <Space direction="horizontal">
                                <FormItem
                                  noStyle
                                  name={[field.name, "isCorrect"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox>Správná odpověď</Checkbox>
                                </FormItem>

                                <Button
                                  type="link"
                                  danger
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  Odebrat
                                </Button>
                              </Space>
                            </>
                          )}
                        </FormItem>
                      ))}

                      <FormItem wrapperCol={{ offset: 5, span: 19 }}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Přidat možnost
                        </Button>

                        <ErrorList errors={errors} />
                      </FormItem>
                    </>
                  )}
                </FormList>

                <Divider orientation="left">Extra</Divider>

                <FormItem
                  label="Tagy"
                  name="tags"
                  rules={[{ required: false }]}
                >
                  <Select mode={"tags"} tokenSeparators={[","]} />
                </FormItem>

                <FormItem
                  label="Postup řešení"
                  name="unformattedSolution"
                  rules={[{ required: false }]}
                >
                  <TextArea placeholder="Neformátované HTML" rows={3} />
                </FormItem>

                {questionId === undefined && (
                  <FormItem>
                    <Button type="primary" htmlType="submit">
                      Vytvořit
                    </Button>
                  </FormItem>
                )}
              </Form>
            </Col>

            <Col span={12}>
              <Divider orientation="left">Náhled na zařízení</Divider>

              <Row justify="center">
                <QuestionPreview
                  question={previewData?.question}
                  answers={previewData?.answers ?? []}
                  questionType={previewData?.questionType}
                />
              </Row>
            </Col>
          </Row>
        </Spin>
      )}
      {existingDataError && (
        <Alert
          message="Něco se pokazilo"
          description="Data otázky se nepodařilo zobrazit"
          type="error"
          showIcon
        />
      )}
    </>
  );
}

export function QuestionPreview(props: {
  question?: string;
  questionType?: QuestionType;
  answers: FormAnswer[];
}) {
  const deviceTextClass = PreviewCss["device-text"];

  const question = props.question ? (
    <TextWithLaTeX text={props.question} />
  ) : (
    <>[Zadání otázky]</>
  );

  const questionTypeTitle =
    props.questionType === "multi-choice"
      ? "Vyberte všechny správné odpovědi"
      : props.questionType === "single-choice"
      ? "Vybere správnou odpověď"
      : "[Typ otázky]";

  const answers = props.answers.length ? (
    <>
      {props.answers.map((answer) => {
        return (
          <AnswerPreview key={answer.text} selected={answer.isCorrect === true}>
            <Text className={deviceTextClass}>
              <TextWithLaTeX text={answer.text as string} />
            </Text>
          </AnswerPreview>
        );
      })}
    </>
  ) : (
    <>[Možnosti]</>
  );

  return (
    <DevicePreview>
      <AndroidScaffold title="10:56" navigationBack>
        <Col
          span={24}
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px 0",
          }}
        >
          <Row>
            <Col span={24} style={{ padding: "0 15px" }}>
              <Title level={4}>Otázka 5 z 20</Title>

              <Row style={{ height: "5px", backgroundColor: "white" }} />

              <Text className={deviceTextClass}>{question}</Text>
            </Col>
          </Row>

          <Row style={{ padding: "0 15px" }}>
            <Col span={24}>
              <Title level={5}>{questionTypeTitle}</Title>

              <Space direction="vertical" style={{ width: "100%" }} size={10}>
                {answers}
              </Space>
            </Col>
          </Row>
        </Col>
      </AndroidScaffold>
    </DevicePreview>
  );
}

function TextWithLaTeX(props: { text: string }) {
  try {
    const blocks = extractLaTeXBlocks(props.text);

    return (
      <>
        {blocks.map((block) => {
          const isInlineMath = block.delimiter?.isInline === true;
          const key = block.text;

          if (block.delimiter === undefined) {
            return (
              <Text key={key} style={{ whiteSpace: "pre-wrap" }}>
                {block.text}
              </Text>
            );
          } else {
            return (
              <TeX
                key={key}
                block={!isInlineMath}
                className={isInlineMath ? PreviewCss["inline-math"] : undefined}
              >
                {block.text}
              </TeX>
            );
          }
        })}
      </>
    );
  } catch {
    return <TeX>{props.text}</TeX>;
  }
}

export const AnswerPreview: React.FC<{ selected?: boolean }> = ({
  children,
  selected,
}) => {
  const border = selected ? "1px solid dodgerblue" : "1px solid lightgray";

  return (
    <Col span={24}>
      <Row
        justify="center"
        style={{ padding: 15, border: border, borderRadius: 10 }}
      >
        {children}
      </Row>
    </Col>
  );
};

export const AndroidScaffold: React.FC<{
  navigationBack?: boolean;
  title?: string;
  trailing?: ReactNode;
}> = ({ children, ...props }) => {
  return (
    <Col
      span={24}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{
          height: 65,
          borderBottom: "1px solid lightgray",
          padding: "0 15px",
        }}
      >
        <Col className="leading">
          {props.navigationBack && <LeftOutlined />}
        </Col>

        {props.title && (
          <Title level={4} style={{ margin: "auto" }}>
            {props.title}
          </Title>
        )}

        <Col className="trailing"></Col>
      </Row>

      <Row style={{ flexGrow: 1 }}>{children}</Row>
    </Col>
  );
};
