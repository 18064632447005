/**
 * Describes how text block is separated within plain text.
 */
 export interface BlockDelimiter {
    left: string,
    right: string,
    isInline: boolean
};

/**
 * Identified block of text. When delimiter is undefined, the
 * block contains plain text. Otherwise, it's a math block.
 */
export interface LaTeXBlock {
    text: string,
    delimiter?: BlockDelimiter
}

/**
 * Default math block delimiters supported in LaTeX.
 * The order of delimiters matters as standard TeX delimiters
 * `$` and `$$` may conflict when their order is swapped.
 */
export const standardDelimiters: BlockDelimiter[] = [
    { left: "$$", right: "$$", isInline: false },
    { left: "\\[", right: "\\]", isInline: false },
    { left: "$", right: "$", isInline: true },
    { left: "\\(", right: "\\)", isInline: true },
];

/**
 * Thrown when given LaTeX string does not contain correctly formatted blocks.
 * 
 * The `expected` field identifies what enclosing block delimiter was expected but was not found.
 */
export interface LaTeXBlockError {
    message: string;
    expected: string;
}

/**
 * Public entry point for identifying LaTeX blocks within arbitrary text.
 * 
 * Throws
 */
export function extractLaTeXBlocks(text: string, supportedDelimiters: BlockDelimiter[] = standardDelimiters): LaTeXBlock[] {
    return supportedDelimiters.reduce(splitBlocksWithDelimiter, [{ text: text }]);
}

/**
 * Splits given text into LaTeX blocks using given set of delimiters.
 */
function splitBlocksWithDelimiter(blocks: LaTeXBlock[], delimiter: BlockDelimiter): LaTeXBlock[] {
    return blocks.reduce((partial, block) => {
        return partial.concat(splitBlockWithDelimiter(block, delimiter));
    }, [] as LaTeXBlock[]);
}

function splitBlockWithDelimiter(block: LaTeXBlock, delimiter: BlockDelimiter): LaTeXBlock[] {
    // If block was previously identified by other delimiter return it unchanged
    if (block.delimiter) {
        return [block];
    }

    const text = block.text
    const blocks: LaTeXBlock[] = []
    let consumedLength = 0

    // The delimiter is required to split whole given input
    // into blocks (unrecognized blocks are returned as plain text blocks)
    while (consumedLength !== text.length) {
        const delimitedBlocks = nextDelimitedBlockWithinText(text, consumedLength, delimiter);
        consumedLength += delimitedBlocks.consumedLength;
        blocks.push(...delimitedBlocks.blocks);
    }

    return blocks
}

function nextDelimitedBlockWithinText(
    text: string,
    startIndex: number,
    delimiter: BlockDelimiter
): { blocks: LaTeXBlock[], consumedLength: number } {
    let leadingText = text.slice(startIndex);
    let consumedLength = 0;
    const leftDelimiterIndex = leadingText.indexOf(delimiter.left);
    const blocks: LaTeXBlock[] = [];
    

    // Delimiter was not found within given text,
    // return text as a single unformatted block
    if (leftDelimiterIndex === -1) {
        const textBlock = {text: leadingText};
        consumedLength += textBlock.text.length;
        return {blocks: [textBlock], consumedLength: consumedLength};
    }

    // The delimiter is not at the beginning of the text,
    // save everything before delimiter as plain text block
    if (leftDelimiterIndex !== 0) {
        const plainText = leadingText.slice(0, leftDelimiterIndex);
        consumedLength += plainText.length;
        leadingText = leadingText.slice(leftDelimiterIndex);
        blocks.push({text: plainText});
    }

    // Remove left delimiter
    consumedLength += delimiter.left.length;
    leadingText = leadingText.slice(delimiter.left.length);
    
    // Find right delimiter
    const rightDelimiterIndex = leadingText.indexOf(delimiter.right);

    // Closing delimiter not found, fail parsing
    if (rightDelimiterIndex === -1) {
        throw {
            message: `Could not find matching enclosing delimiter '${delimiter.right}'.`,
            expected: delimiter.right
        };
    }

    // We found closing delimiter, save math block
    const mathText = leadingText.slice(0, rightDelimiterIndex)
    blocks.push({text: mathText, delimiter: delimiter});
    consumedLength += mathText.length + delimiter.right.length;

    return {blocks: blocks, consumedLength: consumedLength};
};