import { Space, Alert, Input, Button, Row } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { AuthLayout, AuthHeader } from "./AuthLayout";

interface Message {
    type: 'success' | 'error';
    content: string;
}

export function ResetPasswordForm(props: {}) {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<Message | undefined>(undefined);
    const { resetPassword } = useAuth();
    const [form] = useForm();

    const resetPasswordWithEmail = async (values: { email: string }) => {
        setMessage(undefined);
        setIsLoading(true);
        try {
            await resetPassword(values.email);
            form.resetFields();
            setMessage({ type: 'success', content: 'Zkontrolujte svou emailovou schránku pro další instrukce.' });
        } catch (e) {
            setMessage({ type: 'error', content: 'Heslo se nepodařilo resetovat. Zkontrolujte zadaný email a zkuste akci opakovat.' });
        }
        setIsLoading(false);
    }

    return (
        <AuthLayout isLoading={isLoading}>
            <Space direction="vertical" size={20}>
                <AuthHeader />

                {message && <Alert type={message.type} message={message.content} />}

                <Form
                    form={form}
                    onFinish={resetPasswordWithEmail}
                >
                    <FormItem noStyle>
                        <Title level={3}>Reset hesla</Title>
                    </FormItem>

                    <FormItem
                        name="email"
                        rules={[{required: true, type: "email"}]}
                    >
                        <Input placeholder="Email" />
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit" block>Resetovat heslo</Button>
                    </FormItem>
                </Form>
            </Space>

            <Row justify="end">
                <Button type="link"><Link to="/login">Přihlásit se</Link></Button>
            </Row>
        </AuthLayout>
    )
}
