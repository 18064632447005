import { decodeDocument, decodeDocuments } from "../model/Document";
import {
  CreateQuestionDto,
  Question,
  questionDecoder,
  QuestionType,
} from "../model/Question";
import { firestore, functions } from "./Firebase";

export const createQuestion = async (data: CreateQuestionDto) => {
  return functions.httpsCallable("admin-question-addNewQuestion")(data);
};

export type QueryCursor =
  | firebase.default.firestore.DocumentSnapshot<any>
  | string;

export type PagingDirection = "start" | "before" | "after";

export interface FirestorePage<Content> {
  content: Content[];
  start?: QueryCursor;
  end?: QueryCursor;
}

const cursorToSnapshot = async (
  cursor?: QueryCursor
): Promise<firebase.default.firestore.DocumentSnapshot<any> | undefined> => {
  if (cursor === undefined) {
  } else if (typeof cursor === "string") {
    return await firestore.doc(`questions/${cursor}`).get();
  }
  return cursor;
};

export const getQuestions = async (
  direction: PagingDirection = "start",
  cursor?: QueryCursor,
  limit: number = 10
): Promise<FirestorePage<Question>> => {
  var query = firestore.collection("questions").orderBy("dateCreated", "desc");
  var cursorSnapshot = await cursorToSnapshot(cursor);

  if (cursor !== undefined) {
    if (direction === "start") {
      query = query.startAt(cursorSnapshot);
    } else if (direction === "before") {
      query = query.endBefore(cursorSnapshot);
    } else if (direction === "after") {
      query = query.startAfter(cursorSnapshot);
    }
  }

  query =
    direction === "before" ? query.limitToLast(limit) : query.limit(limit);

  const result = await query.get();
  const questions: Question[] = await decodeDocuments(
    result.docs,
    questionDecoder
  );

  return {
    start: result.docs.find(() => true),
    end:
      result.docs.length > 0 ? result.docs[result.docs.length - 1] : undefined,
    content: questions,
  };
};

export const getQuestion = async (id: string): Promise<Question> => {
  const data = await firestore.doc(`questions/${id}`).get();
  const question: Question = await decodeDocument(data, questionDecoder);
  return question;
};
