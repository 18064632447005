import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useQuery(): [
  (param: string) => string | undefined,
  (newParams: Record<string, string | undefined>) => void
] {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  return [
    (param) => params.get(param) || undefined,
    (newParams) => {
      Object.keys(newParams).forEach((key) => {
        const value = newParams[key];
        if (value !== undefined) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      });
      push({ pathname, search: params.toString() });
    },
  ];
}
