import 'firebase/auth';
import { createContext, useContext, useEffect, useState } from "react";
import { app } from "../model/FirebaseConfig";
import { Session, User } from '../model/Session';

const auth = app.auth();
const AuthContext = createContext<Session>({
    login: async () => { },
    logout: async () => { },
    resetPassword: async () => { }
});

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider: React.FC = ({children}) => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    
    const login = async (email: string, password: string) => {
        await auth.signInWithEmailAndPassword(email, password);
    };

    const resetPassword = async (email: string) => {
        await auth.sendPasswordResetEmail(email);
    }

    const logout = async () => {
        await auth.signOut();
    };

    useEffect(() => {
        const subscription = auth.onAuthStateChanged(user => {
            const newUser = user
                ? { id: user.uid, email: user.email ?? "" }
                : undefined;
            setUser(newUser);
            setIsLoading(false);
        });

        return subscription;
    }, []);

    return (
        <AuthContext.Provider value={{
            login: login,
            logout: logout,
            resetPassword: resetPassword,
            user: user
        }}>
            {!isLoading && children}
        </AuthContext.Provider>
    );
}