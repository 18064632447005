import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useAuth } from "../../context/AuthContext";

export function Logout(props: {}) {
    const { logout } = useAuth();
    const [isLoggedOut, setIsLoggedOut] = useState(false);


    useEffect(() => {
        const doLogout = async () => {
            try {
                await logout();
            } catch { }

            setIsLoggedOut(true);
        };

        doLogout();
    }, []);

    return (
        <>
            { isLoggedOut && <Redirect to="/login"/> }
        </>
    );
}
