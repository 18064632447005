import { Row, Col, Button, Spin, Card } from "antd";
import React from "react";
import logo from '../../assets/logo.svg';

export const AuthHeader = () => {
    return (
        <Row justify="center" className="auth-header">
            <Col span={16}>
                <Button type="link" href="https://studybuddy.cz" className="logo">
                    <img src={logo}/>
                </Button>
            </Col>
        </Row>
    );
}

export const AuthLayout: React.FC<{className?: string, isLoading?: boolean}> = ({className, isLoading, children}) => {
    return (
        <Row justify="center" style={{padding: 50}}>
            <Col xs={24} md={12} lg={8} xl={6}>
                <Spin spinning={isLoading}>
                    <Card className={className}>
                        {children}
                    </Card>
                </Spin>
            </Col>
        </Row>
    );
}
